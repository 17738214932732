import { FC } from 'react';

import { Text } from '@weave/design-system';

import FileInputField from './file-input-field/file-input-field.component';
import { inputContainerStyle } from './card-capture-field.styles';
import { MaxImageFileSize } from '@/utils/image';

interface CardCaptureFieldProps {
  label: string;
  error: string;
  reviewMode?: boolean;
}

const CardCaptureField: FC<CardCaptureFieldProps> = ({
  label,
  error,
  reviewMode,
  ...otherProps
}) => (
  <div>
    <Text weight="regular">{label}</Text>

    <div css={inputContainerStyle}>
      <FileInputField type="front" reviewMode={reviewMode} {...otherProps} />
      <FileInputField type="back" reviewMode={reviewMode} {...otherProps} />
    </div>
    <Text size="small" color="light">
      Maximum size allowed for each file is {MaxImageFileSize / 1024 / 1024} MB.
    </Text>

    {error && (
      <Text color="error" size="small">
        {error}
      </Text>
    )}
  </div>
);

export default CardCaptureField;
